<template>
	<div class="wrap">
		<van-list
			:finished="finished"
			finished-text="没有更多了"
			v-model="loading"
			offset="300"
			@load="getConversationList"
			:immediate-check="false"
		>
		<ul>
			<div v-if="!doctorList.length" class="no-data flex f-jc-c f-ai-c">
				<div class="flex f-d-c f-ai-c">
					<img src="../../assets/images/no-data.png" alt="">
					<span>暂无数据</span>
				</div>
			</div>
			<li v-for="(item, index) in doctorList" :key="index" class="flex" @click="toDoctorDetail(item.doctorId, item.conversationId, item.patientId)">
				<!-- {{ const { patient = {} } = item}} -->
				<div v-if="item.doctor.avatar" class="avatar" :style="{background: `url(${item.doctor.avatar})`}">
					<span class="msg-count" v-if="item.newMessage">{{+item.newMessage > 99 ? `99+` : item.newMessage }}</span>
				</div>
				<div v-else class="avatar avatar-d">
					<span class="msg-count" v-if="item.newMessage">{{+item.newMessage > 99 ? `99+` : item.newMessage }}</span>
				</div>
				<div class="right">
					<div class="doctor-info flex f-jc-sb">
						<div class="name flex ">
							<span>{{item.doctor.name}} {{item.doctor.titleName}}</span>
							<span>{{item.doctor.deptName}}</span>
						</div>
						<span class="time">{{dateFormat(new Date(item.updatedAt), 'MM月dd日')}}</span>
					</div>
					<p>{{cutdownString(item.doctor.description ? item.doctor.description : '无', 15)}}</p>
					<div class="msg">
						{{cutdownString(item.lastMessage, 18)}}
					</div>
				</div>
				<span class="patient">{{item.patient.name}}</span>
			</li>
		</ul>
		</van-list>
	</div>
</template>
<script>

import { conversationsList } from '@/request/api/user';
import { dateFormat } from '@/utils/time';
import { cutdownString } from '@/utils';

export default {
	data () {
		return {
			defaultImg: require('@/assets/images/doctor-def.png'),
			doctorList: [],
			pageInfo: {
				currentPage: 0,
				pageSize: 10
			},
			finished: false,
			loading: false
		};
	},
	created () {
		this.doctorList = [];
		this.getConversationList();
	},
	methods: {
		dateFormat (date, fmt) {
			return dateFormat(date, fmt);
		},
		cutdownString (str, length) {
			return cutdownString(str, length);
		},
		getConversationList () {
			this.pageInfo.currentPage++;
			this.loading = true;
			conversationsList({
				currentPage: this.pageInfo.currentPage,
				pageSize: this.pageInfo.pageSize
			}).then(data => {
				// console.log('conversationsList======', data);
				if (data && data === 'retry') {
					this.pageInfo.currentPage = 0;
					this.loading = true;
					this.getConversationList();
				} else if (data) {
					this.pageInfo = data.pageInfo;
					for (let i = 0; i < data.items.length; i++) {
						if (!data.items[i].patient) {
							data.items[i].patient = {};
						}
						if (data.items[i].lastMessage && data.items[i].lastMessage) {
							if (data.items[i].lastMessage.MsgType === 'TIMSoundElem') {
								data.items[i].lastMessage = '系统消息： 您收到一条语音';
							} else if (data.items[i].lastMessage.MsgType === 'TIMImageElem') {
								data.items[i].lastMessage = '系统消息： 您收到一张图片';
							} else if (data.items[i].lastMessage.MsgType === 'TIMTextElem') {
								data.items[i].lastMessage = data.items[i].lastMessage.MsgContent.Text;
							} else if (data.items[i].lastMessage.MsgType === 'TIMCustomElem') {
								data.items[i].lastMessage = '系统消息： 您收到一张卡片';
							} else {
								data.items[i].lastMessage = '无新消息';
							}
						} else {
							data.items[i].lastMessage = '无新消息';
						}
						this.doctorList.push(data.items[i]);
					}
					if (+this.pageInfo.currentPage === data.pageInfo.totalPage || data.pageInfo.totalPage === 0) {
						this.finished = true;
					}
					this.loading = false;
				}
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		toDoctorDetail (id, conversationId, patientId) {
			this.$root.go('/dip', { doctorId: id, at: 2, conversationId, patientId });
		}
	}
};
</script>
<style lang="less" scoped>
	.no-data {
		height: 300px;
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8B8B8B;
		}
	}
	.wrap {
		box-shadow: 0 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 4px;
		border: 1px solid #EFEFEF;
		overflow: auto;
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 15px 12px;
		ul {
			background-color: #FFFFFF;
			display: flex;
			flex-direction: column;
			li {
				padding: 20px 16px 15px;
				position: relative;
				border-bottom: 1px solid #F3F3F3;
				.avatar {
					width: 55px;
					height: 55px;
					border-radius: 50%;
					margin-right: 9px;
					background-size: cover !important;
					background-repeat: no-repeat !important;
					position: relative;
					.msg-count {
						position: absolute;
						// width: 28px;
						// height: 16px;
						padding: 0 5px;
						background-color: #f33d3a;
						color: #FFFFFF;
						border-radius: 8.5px;
						text-align: center;
						line-height: 16px;
						right: -2px;
						top: -5px;
						border: .03px solid #FFFFFF;
						font-size: 10px;
					}
				}
				.avatar-d {
					background-image: url('../../assets/images/doctor-def.png');
				}
				.right {
					position: relative;
					flex: 1;
					.doctor-info {
						.name {
							flex-direction: column;
							// width: 180px;
							span:nth-child(1) {
								font-size: 15px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #232830;
								line-height: 15px;
								margin-bottom: 10px;
							}
							span:nth-child(2) {
								font-size: 12px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #232830;
								line-height: 12px;
							}
						}
						.time {
							color: #959595;
							font-size: 11px;
						}
					}
					p {
						margin: 14px 0 12px 0;
						color: #7D7D7D;
						font-size: 12px;
					}
					.msg {
						height: 30px;
						background: #F8F8F8;
						border-radius: 2.75px;
						padding-left: 7.5px;
						font-size: 11px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #7D7D7D;
						line-height: 30px;
						box-sizing: border-box;
					}
				}
				.patient {
					position: absolute;
					display: inline-block;
					top: 0;
					left: 0;
					height: 16px;
					background: #f3f3f3;
					border-radius: 0 0 0 4px;
					background-color: #f3f3f3;
					padding: 0 4px;
					line-height: 16px;
					color:#7D7D7D;
					box-sizing: border-box;
					font-size: 10px;
				}
			}
		}
	}
</style>